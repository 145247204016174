import { makeStyles, Typography, Box } from "@material-ui/core"
import React from "react"
import { DigicLink } from "../digic-link"

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    "& h2": {
      transition: "all 800ms ease !important",
    },
    "& img": {
      transition: "all 800ms ease !important",
    },
    "&:hover img": {
      transform: "scale(1.05)",
      filter: "brightness(0.8)",
    },
    transition: "all 800ms ease",
  },
  title: {
    position: "absolute",
    margin: "auto",
    filter: "drop-shadow(0px 0px 2px #0006)",
    marginBottom: theme.spacing(2),
  },
  image: {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    filter: "brightness(0.5)",
  },
}))

export const Project = ({ title, image, id }) => {
  const classes = useStyles()
  return (
    <DigicLink to={`/productions/${id}`}>
      <Box className={classes.container}>
        <img className={classes.image} alt={title} src={image}></img>
        <Typography className={classes.title} variant="h2">
          {title}
        </Typography>
      </Box>
    </DigicLink>
  )
}
