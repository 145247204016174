import { Typography, Grid, Box, Button, makeStyles } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import LayoutProductions from "../../components/layout/layout-productions"
import { Project } from "../../components/productions/project"
import Fade from "react-reveal/Fade"
import { Link } from "react-scroll"
import HeroImage from "../../images/productions_hero.png"
import AnotherHomeTile from "../../images/productions-tiles/Ah_tile.jpeg"
import ArtiliaTile from "../../images/productions-tiles/Artilia_tile.jpg"
import CargoTile from "../../images/productions-tiles/Cargo_tile.png"
import CrystalwishTile from "../../images/productions-tiles/Crystalwish_tile.jpg"
import FearMonsters from "../../images/productions-tiles/FearMonsters_tile.jpg"
import WorldsApartTile from "../../images/productions-tiles/WA_tile.png"

const useStyles = makeStyles(theme => ({
  workItem: {
    aspectRatio: "16/9",
  },
  projectsTitle: {
    marginBottom: "64px",
    marginTop: "16px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "24px",
    },
  },
  projectsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    maxWidth: "1440px",
    marginBottom: "96px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0px",
    },
  },
  aboutContainer: {
    display: "flex",
    gridGap: "128px",
    minHeight: "80vh",
    alignItems: "center",
    maxWidth: "1440px",
    margin: "48px",
    padding: "96px 0",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
      justifyContent: "center",
      gridGap: "48px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "24px",
    },
  },
  aboutImage: {
    display: "flex",
    width: "100vw",
    height: "800px",
    maxWidth: "600px",
    objectFit: "cover",
    maxHeight: "80vh",
    [theme.breakpoints.down("md")]: {
      objectFit: "cover",
      maxWidth: "initial",
      maxHeight: "500px",
    },
  },
  grid: {
    marginBottom: "64px",
  },
  //slider
  dot: {
    backgroundColor: "#F26522",
    border: "0px",
    width: "50px",
    height: "4px",
    margin: "8px",
    "&:active": { backgroundColor: "#999" },
  },
  heroLogo: {
    width: "240px",
    marginBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "140px",
      marginBottom: "8px",
    },
  },
  heroContentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    zIndex: "3",
    width: "100%",
    padding: theme.spacing(4),
    paddingTop: "88px",
    height: "100%",
    background:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.4) 76.15%, rgba(0, 0, 0, 0.4) 78.62%, rgba(0, 0, 0, 0.4) 78.62%, rgba(0, 0, 0, 0.4) 81.44%, #000000 100%)",
    // [theme.breakpoints.down("sm")]: { height: "90vh" },
  },
  heroDescription: {
    maxWidth: "700px",
    margin: "32px 0",
    [theme.breakpoints.down("sm")]: { fontSize: "14px", margin: "24px 0" },
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      height: "42px",
      fontSize: "14px",
    },
  },
}))

const ProductionsHomePage = () => {
  const classes = useStyles()

  const thumbnailImages = [
    AnotherHomeTile,
    ArtiliaTile,
    WorldsApartTile,
    FearMonsters,
    CargoTile,
    CrystalwishTile,
  ]

  const data = useStaticQuery(graphql`
    {
      strapi {
        productionsHomePage {
          ProductionsHomeLogo {
            url
          }
          ProductionsHomeLead
          ProductionsHomeButton
          ProductionsHomeFirstImage {
            url
          }
          ProductionsHomeSecondImage {
            url
          }
          ProductionsHomeThirdImage {
            url
          }
          ProductionsAboutTitle
          ProductionsAboutLead
          ProductionsProjectsTitle
          ProductionsIdeaTitle
          ProductionsIdeaLead
          ProductionsIdeaButton
          ProductionsIdeaBg {
            url
            imageFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
        projects {
          id
          ProjectTitle
          ProjectThumbnail {
            url
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <LayoutProductions mainPage>
      <Box color="#999" style={{ paddingTop: "120px", overflow: "hidden" }}>
        <Box height="70vh" position="relative">
          <img
            src={HeroImage}
            alt="logo"
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              objectFit: "cover",
            }}
          />
          <Box className={classes.heroContentWrapper}>
            <img
              src={data.strapi.productionsHomePage.ProductionsHomeLogo.url}
              alt="logo"
              className={classes.heroLogo}
            />
            <Typography
              variant="h3"
              align="center"
              className={classes.heroDescription}
            >
              {data.strapi.productionsHomePage.ProductionsHomeLead}
            </Typography>
            <Link to="section" offset={-120} smooth={true}>
              <Button variant="contained" className={classes.button}>
                {data.strapi.productionsHomePage.ProductionsHomeButton}
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box className={classes.aboutContainer} id="section">
          <Fade>
            <Box
              display="flex"
              alignItems="start"
              justifyContent="center"
              flexDirection="column"
              minHeight="30vh"
              maxWidth="800px"
              margin="0 24px"
            >
              <Typography
                variant="h1"
                color="primary"
                style={{ marginBottom: "16px", width: "100%" }}
              >
                {data.strapi.productionsHomePage.ProductionsAboutTitle}
              </Typography>
              <Typography
                variant="h3"
                color="primary"
                style={{ marginBottom: "16px", width: "100%", color: "#fff" }}
              >
                As filmmakers, we’ve been looking forward to bringing our own
                stories to life ever since the start of our careers.
              </Typography>
              <Typography variant="body1">
                {data.strapi.productionsHomePage.ProductionsAboutLead}
              </Typography>
              <Link to="projects" smooth offset={-120}>
                <Button variant="outlined" style={{ marginTop: "48px" }}>
                  Our projects
                </Button>
              </Link>
            </Box>
            {/*
            <Box>
              <Parallax bgImage={AboutImage} strength={500}>
                <Box className={classes.aboutImage} />
              </Parallax>
            </Box>
             */}
          </Fade>
        </Box>
        <Box className={classes.projectsContainer} id="projects">
          <Typography variant="h1" className={classes.projectsTitle}>
            {data.strapi.productionsHomePage.ProductionsProjectsTitle}
          </Typography>
          <Grid className={classes.grid} spacing={2} container>
            {data.strapi.projects.map(
              ({ ProjectTitle, ProjectThumbnail, id }, index) => {
                return (
                  <Grid
                    key={ProjectTitle}
                    item
                    xs={12}
                    md={6}
                    lg={6}
                    xl={4}
                    className={classes.workItem}
                  >
                    <Project
                      title={ProjectTitle}
                      image={thumbnailImages[index]}
                      id={id}
                    />
                  </Grid>
                )
              }
            )}
          </Grid>
        </Box>
        {/*
        <TextSection
          backgroundImage={
            data.strapi.productionsHomePage.ProductionsIdeaBg.url
          }
          title={data.strapi.productionsHomePage.ProductionsIdeaTitle}
          description={data.strapi.productionsHomePage.ProductionsIdeaLead}
          buttonText={data.strapi.productionsHomePage.ProductionsIdeaButton}
          buttonLink="/productions/submission"
        />
        */}
      </Box>
    </LayoutProductions>
  )
}

export default ProductionsHomePage
